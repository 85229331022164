import * as React from "react";
import { useState } from 'react';
import './Form.css';
import logoSVG from "../../assets/logosvg.svg"

// function encode(data) {
//     return Object.keys(data)
//         .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
//         .join('&');
// }

function ApplicantSection(props) {
    return (
        <div className="form-section">
            <div className="section-header">
                <b>Applicant #{props.numberId}</b>
            </div>

            <div className="group-fields">
                <div className="horizontal-input">
                    <div className="input-field">
                        <input required className="justify-text-left" type="text" id={"firstname" + props.numberId} name={"firstname" + props.numberId} />
                        <label>Enter your first name</label>
                    </div>

                    <div className="input-field">
                        <input required className="justify-text-left" type="text" id={"lastname" + props.numberId} name={"lastname" + props.numberId} />
                        <label>Enter your last name</label>
                    </div>
                </div>

                <div className="input-field">
                    <input required className="justify-text-left" type="phone" id={"phone" + props.numberId} name={"phone" + props.numberId} />
                    <label>Enter your phone number</label>
                </div>

                <div className="input-field">
                    <input required className="justify-text-left" type="email" id={"email" + props.numberId} name={"email" + props.numberId} />
                    <label>Enter your email address</label>
                </div>
            </div>

            <br />

            <div className="section-header">
                <b>Verification and Credit</b>
            </div>

            <div className="horizontal-input">
                <label htmlFor="move-in-date">Intended move-in date:</label>
                <input required type="date" id={"move-in-date" + props.numberId} name={"move-in-date" + + props.numberId} />
            </div>

            <br />

            <div className="horizontal-input">
                <label htmlFor="monthly-income">Total Monthly Income:</label>
                <input required className="justify-text-right" type="number" id={"monthly-income" + props.numberId} name={"monthly-income" + props.numberId} placeholder="$$$" min="0" step="100" />
            </div>

            <br />

            <div className="horizontal-input">
                <label htmlFor="employment-length">Length of employment(months):</label>
                <input required className="justify-text-right" type="number" id={"employment-length" + props.numberId} name={"employment-length" + props.numberId} placeholder="0" min="0" step="1" />
            </div>

            <br />

            <div className="section-header">
                <b>Applicant Credit Score</b>
            </div>

            <div className="horizontal-input">
                <label htmlFor="credit-score">Estimated Credit Score:</label>
                <input required className="justify-text-right" type="number" id={"credit-score" + props.numberId} name={"credit-score" + props.numberId} placeholder="400+" min="0" step="50" />
            </div>

            <br />

            <div className="horizontal-input">
                <label htmlFor="prior-evictions">Prior Evictions?</label>
                <select required name={"prior-evictions" + props.numberId} id={"prior-evictions" + props.numberId}>
                    <option></option>
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                </select>
            </div>

            <br />

            <div className="horizontal-input">
                <label htmlFor="open-collections">Open Collections?</label>
                <select required name={"open-collections" + props.numberId} id={"open-collections" + props.numberId}>
                    <option></option>
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                </select>
            </div>

            <br />

            <div className="horizontal-input">
                <label htmlFor="current-rent">Current Rent Payment:</label>
                <input required className="justify-text-right" type="number" id={"current-rent" + props.numberId} name={"current-rent" + props.numberId} placeholder="$$$" min="0" step="100" />
            </div>

            <br />

            <div className="horizontal-input">
                <label htmlFor="deposit-ready">Are you deposit ready?</label>
                <select required name={"deposit-ready" + props.numberId} id={"deposit-ready" + props.numberId}>
                    <option></option>
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                    <option value="Will be by move date">Will be by move date</option>
                </select>
            </div>

            <br />

            <div className="horizontal-input">
                <label htmlFor="applied-elsewhere">Have you recently applied elsewhere?</label>
                <select required name={"applied-elsewhere" + props.numberId} id={"applied-elsewhere" + props.numberId}>
                    <option></option>
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                </select>
            </div>
        </div>
    );
}

function Form(props) {
    // const [state, setState] = useState({})
    const [numOfApplicants, setNumOfApplicants] = useState(1);
    const [toggleCosign, setToggleCosign] = useState(false);
    const [secondCosigner, setSecondCosign] = useState(false);

    const [togglePets, setTogglePets] = useState(false);
    const [hasCat, setHasCat] = useState(false);
    const [hasDog, setHasDog] = useState(false);
    const [hasOther, setHasOther] = useState(false);
    const [toggleAgree, setToggleAgree] = useState(false);


    const handleNumOfApplicants = (e) => {
        setNumOfApplicants(e.target.value);
    }

    const handleToggleCosign = (e) => {
        setToggleCosign(!toggleCosign);
    }

    const handleSecondCosign = (e) => {
        setSecondCosign(!secondCosigner);
    }

    const handleTogglePets = (e) => {
        setTogglePets(!togglePets);
    }

    const handleHasCat = (e) => {
        setHasCat(!hasCat);
    }

    const handleHasDog = (e) => {
        setHasDog(!hasDog);
    }

    const handleHasOther = (e) => {
        setHasOther(!hasOther);
    }

    const handleToggleAgree = (e) => {
        setToggleAgree(!toggleAgree);
    }

    // const handleChange = (e) => {
    //     setState({ ...state, [e.target.name]: e.target.value })
    // }

    function generateApplicantForms() {
        let forms = [];

        for (let i = 1; i <= numOfApplicants; i++) {
            forms.push(<ApplicantSection numberId={i} key={i} />);
        }

        return forms;
    }

    // const handleSubmit = (e) => {
    //     e.preventDefault()

    //     const form = e.target

    //     fetch('https://api.netlify.com/api/v1/sites/25058808-5ccf-4c3a-8432-2dfece181897/submissions', {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    //         body: encode({
    //             'form-name': form.getAttribute('name'),
    //             ...state,
    //         }),
    //     })
    //         .then(() => { })
    //         .catch((error) => alert(error))
    // }

    return (
        <div className="form-wrap">
            <form
                name="questionnaire"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                className="form-content"
                onSubmit="submit"
            >
                <input type="hidden" name="form-name" value="adinqra-questionnaire" />

                <div className="form-header">
                    {/* <div className="header-text">
                        - Adinqra Questionnaire
                    </div> */}

                    <img className="adin-logo" src={logoSVG} alt="adinqra-logo" />

                    {/* <hr className="extendbar"></hr> */}
                </div>

                {/* Number of applicants */}
                <div className="form-section">
                    <div className="section-header">
                        <b>Total applicants</b>
                    </div>

                    <div className="horizontal-input">
                        <label htmlFor="num-applicants">Number of Applicants:</label>
                        <input required className="justify-text-left"
                            type="number" id="num-applicants"
                            name="num-applicants" min={1} step={1} max={8}
                            value={numOfApplicants}
                            onChange={handleNumOfApplicants}
                        />
                    </div>
                    <div id="applicant-details"></div>
                </div>

                {/* General Section */}
                {/* {Array(numOfApplicants).map((x, index) => <ApplicantSection numberId={x} key={index} />)} */}
                {generateApplicantForms()}
                {/* {Array(numOfApplicants).map(function (x) {
                    return (<ApplicantSection numberId={x} key={x} />)
                }
                )} */}

                {/* Cosigner Section */}
                <div className="form-section">
                    <div className="section-header">
                        <b>Cosigner Information</b>
                    </div>

                    <div className="checkbox-wrap">
                        <label htmlFor="cosigner">Applying with a cosigner?</label>
                        <input className="" type="checkbox" id="cosigner" name="cosigner" checked={toggleCosign} value={toggleCosign} onChange={handleToggleCosign} />
                    </div>

                    {toggleCosign ? (
                        <>
                            <div className="group-fields">
                                <div className="input-field">
                                    <input required className="justify-text-left" type="text" id="cosigner-firstname" name="cosigner-firstname" />
                                    <label>Enter cosigner's first name</label>
                                </div>

                                <div className="input-field">
                                    <input required type="text" className="justify-text-left" id="cosigner-lastname" name="cosigner-lastname" />
                                    <label>Enter cosigner's last name</label>
                                </div>

                                <div className="input-field">
                                    <input required type="text" className="justify-text-left" id="cosigner-phone" name="cosigner-phone" />
                                    <label>Enter cosigner's phone number</label>
                                </div>

                                <div className="input-field">
                                    <input required type="email" className="justify-text-left" id="cosigner-email" name="cosigner-email" />
                                    <label>Enter cosigner's email address</label>
                                </div>
                            </div>

                            <br />

                            <div className="checkbox-wrap">
                                <label htmlFor="cosigner">Have a second cosigner?</label>
                                <input className="" type="checkbox" id="secondcosigner" name="secondcosigner" checked={secondCosigner} value={secondCosigner} onChange={handleSecondCosign} />
                            </div>

                            {secondCosigner ? (
                                <div className="group-fields">
                                    <div className="input-field">
                                        <input required className="justify-text-left" type="text" id="cosigner-firstname2" name="cosigner-firstname2" />
                                        <label>Enter cosigner's first name</label>
                                    </div>

                                    <div className="input-field">
                                        <input required type="text" className="justify-text-left" id="cosigner-lastname2" name="cosigner-lastname2" />
                                        <label>Enter cosigner's last name</label>
                                    </div>

                                    <div className="input-field">
                                        <input required type="text" className="justify-text-left" id="cosigner-phone2" name="cosigner-phone2" />
                                        <label>Enter cosigner's phone number</label>
                                    </div>

                                    <div className="input-field">
                                        <input required type="email" className="justify-text-left" id="cosigner-email2" name="cosigner-email2" />
                                        <label>Enter cosigner's email address</label>
                                    </div>
                                </div>
                            ) : null}

                        </>
                    ) : null}
                </div>

                {/* Pet Info Section */}
                <div className="form-section">
                    <div className="section-header">
                        <b>Pet Information</b>
                    </div>

                    <div className="checkbox-wrap">
                        <label htmlFor="pet">Do you have a pet/companion animal?</label>
                        <input type="checkbox" id="pet" name="pet" checked={togglePets} value={togglePets} onChange={handleTogglePets} />
                    </div>

                    {togglePets ? (
                        <div id="pet-options">
                            <br />
                            <div className="horizontal-input">
                                <label htmlFor="cat">Cat:</label>
                                <input type="checkbox" id="cat" name="cat" checked={hasCat} value={hasCat} onChange={handleHasCat} />
                            </div>

                            <div className="horizontal-input" id="cat-amount" style={!hasCat ? { opacity: 0.35, pointerEvents: "none" } : { opacity: 1 }}>
                                <label htmlFor="num-cats">Number of Cats: </label>
                                <input type="number" id="num-cats" name="num-cats" min="1" max="10" />
                            </div>

                            <br />

                            <div className="horizontal-input">
                                <label htmlFor="dog">Dog:</label>
                                <input type="checkbox" id="dog" name="dog" checked={hasDog} value={hasDog} onChange={handleHasDog} />
                            </div>

                            <div className="horizontal-input" id="dog-amount" style={!hasDog ? { opacity: 0.35, pointerEvents: "none" } : { opacity: 1 }}>
                                <label htmlFor="num-dogs">Number of Dogs: </label>
                                <input type="number" id="num-dogs" name="num-dogs" min="1" max="10" />
                            </div>

                            <br />

                            <div className="horizontal-input">
                                <label htmlFor="dog">Other:</label>
                                <input type="checkbox" id="other-pet" name="other-pet" checked={hasOther} value={hasOther} onChange={handleHasOther} />
                            </div>

                            <div className="vertical-input" id="other-amount" style={!hasOther ? { opacity: 0.35, pointerEvents: "none" } : { opacity: 1 }}>
                                <div className="horizontal-input">
                                    <label htmlFor="num-dogs">What kind of pet?</label>
                                    <input className="justify-text-right" type="text" id="about-other" name="about-other" placeholder="Pet Type" />
                                </div>
                                <div className="horizontal-input">
                                    <label htmlFor="num-other">Number: </label>
                                    <input type="number" id="num-other" name="num-other" min="1" max="10" />
                                </div>
                            </div>

                            <br />

                            <div className="vertical-input">
                                <label htmlFor="animal-weights">Please enter the weight of your pet(s):</label>
                                <textarea className="areas-of-interest-field" type="text" id="animal-weights" name="animal-weights"></textarea>
                            </div>

                            <br />
                        </div>
                    ) : null}
                </div>


                {/* Rental Criterea & Preferences */}
                <div className="form-section">
                    <div className="section-header">
                        <b>Rental Preferences</b>
                    </div>

                    <div className="horizontal-input">
                        <label htmlFor="rent-budget">Monthly Rent Budget:</label>
                        <input required className="justify-text-right" type="number" id="rent-budget" name="rent-budget" placeholder="$$$" min="0" step="100" />
                    </div>

                    <br />

                    <div className="horizontal-input">
                        <label htmlFor="num-beds">Number of Bedrooms:</label>
                        <input required className="justify-text-right" type="number" id="num-beds" name="num-beds" placeholder="1" min="1" max="5" step="1" />
                    </div>

                    <br />

                    <div className="horizontal-input">
                        <label htmlFor="num-beds">Number of Bathrooms:</label>
                        <input required className="justify-text-right" type="number" id="num-baths" name="num-baths" placeholder="1" min="1" max="5" step="1" />
                    </div>

                    <br />

                    <div className="vertical-input">
                        <label htmlFor="areas-of-interest">Please list areas of interest (Fishtown, South Philadelphia, etc.):</label>
                        <textarea className="areas-of-interest-field" type="text" id="areas-of-interest" name="areas-of-interest"></textarea>
                    </div>

                    <br />

                    <div className="section-header">
                        <b>Additional Features</b>
                    </div>

                    <div className="horizontal-input">
                        <label htmlFor="washer-dryer">Washer/Dryer?</label>
                        <select name="washer-dryer" id="washer-dryer">
                            <option></option>
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                        </select>
                    </div>

                    <br />

                    <div className="vertical-input">
                        <label htmlFor="other-preferences">Other preferences/requirements:</label>
                        <textarea className="areas-of-interest-field" type="text" id="other-preferences" name="other-preferences"></textarea>
                    </div>

                    {/* <div className="horizontal-input">
                        <label htmlFor="central-cooling">Central Cooling?</label>
                        <select name="central-cooling" id="central-cooling">
                            <option></option>
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                        </select>
                    </div>

                    <br />

                    <div className="horizontal-input">
                        <label htmlFor="off-street">Off Street Parking?</label>
                        <select name="off-street" id="off-street">
                            <option></option>
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                        </select>
                    </div> */}
                </div>

                <div className="form-section">
                    <div className="terms-wrap">
                        <input required type="checkbox" id="agree-to-terms" name="agree-to-terms"
                            checked={toggleAgree} value={toggleAgree} onChange={handleToggleAgree}
                        />
                        <label htmlFor="agree">
                            <b>
                                I agree to be contacted by Mishael Scott via call, email, and text. To opt out, you can reply 'stop' at any time or click the unsubscribe link in the emails. Message and data rates may apply.
                            </b>
                        </label>
                    </div>
                </div>

                <div className="form-section end-form-gap">
                    <button className="submit-button" type="submit" id="submit-button">Done</button>
                </div>

                {/* <div id="confirmationModal">
                    <div className="modal-content">
                        <span className="close-button">&times;</span>
                        <p>Form submitted successfully!</p>
                    </div>
                </div> */}
            </form >
        </div >
    );
}

export default Form;