import * as React from "react";
import './Disclosures.css';
import discPDF from "../../assets/adinqradisc.pdf";
import logoSVG from "../../assets/logosvg.svg"
import { Viewer, Worker } from '@react-pdf-viewer/core';
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const DISC_LINK_REDIRECT = "https://na4.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=82bc48fd-b01d-4711-945c-8bc39016f753&env=na4&acct=be89b9c4-f04a-4a69-a734-ea3ba53c37db&v=2";

function Disclosures(props) {
    // const defaultLayoutPluginInstance = defaultLayoutPlugin();



    return (
        <div className="disclosures-wrap">
            <div className="disclanding-wrap">
                <div className="disclosures-landing">
                    <div className="disc-text">
                        {/* Adinqra's Disclosures */}
                        <img className="disc-logo" src={logoSVG} alt="adinqra-logo" />
                    </div>
                    <hr className="extendbar"></hr>
                </div>
                <div className="pdf-viewer-wrap">
                    {/* <iframe title="disclosures-pdf" className="pdf-viewer" src="https://css4.pub/2015/icelandic/dictionary.pdf" width="400px" height="300px" /> */}

                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
                        <div style={{
                            height: '100%', width: "100%", border: '1px solid rgba(0, 0, 0, 0.3)',
                        }}>
                            <Viewer
                                fileUrl={discPDF}
                            // plugins={[
                            //     defaultLayoutPluginInstance,
                            // ]}
                            />
                        </div>
                    </Worker>
                </div>

                {/* <div className="comingsoon">Coming soon</div> */}
                <button className="sign-btn">
                    <a target="_blank" rel="noreferrer" href={DISC_LINK_REDIRECT}>
                        Sign
                    </a>
                </button>
            </div>
        </div>
    );
}

export default Disclosures;