import * as React from "react";
import './Footer.css';
import logoSVG from "../../assets/logosvg.svg"

function Footer(props) {
    return (
        <div className="footer-wrap">
            <div className="web-design-title footer-hide">
                -Design by Nathan Breen
            </div>
            <div className="footer-container">
                <div className="footer-text">
                    <img className="footer-logo" src={logoSVG} alt="adinqra-logo" />
                    <div className="white-emphasis">
                        {"Adinqra - "}
                    </div>
                    2024
                </div>
            </div>

            <a href="https://www.nbreen.dev" target="_blank" rel="noreferrer" className="web-design-title">
                Design by Nathan Breen
            </a>
        </div>
    );
}

export default Footer;